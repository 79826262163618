import Seo from 'modules/app/components/Seo';
import { RecurringPolicyPage } from 'modules/legal';
import React from 'react';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const RecurringPaymentPolicy: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Recurring Payment Policy - Roango"
        description="We respect your privacy which is why we provide a service that lives up to strict user privacy standards."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <RecurringPolicyPage />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default RecurringPaymentPolicy;
